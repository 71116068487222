<template>
  <div class="home app-content">
    <div class="picture"></div>
    <div class="overlay"></div>
    <div class="content">
      <div class="cards">
        <CardFfantasy />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CardFfantasy from './../components/cards/CardFfantasy.vue';

@Component({
  components: {
    CardFfantasy
  }
})
export default class HomeView extends Vue {}
</script>

<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
  position: relative;

  .overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    position: absolute;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(58, 24, 144, 0.3) 50%, rgba(255,255,255,0) 100%);
  }

  .picture {
    z-index: 1;
    position: relative;
    flex-grow: 1;
    background-image: url(https://i.imgur.com/IPgySKo.jpg);
    background-size: cover;
    background-position: center;
  }

  .content {
    z-index: 2;
    position: fixed;
    padding: 3rem 2rem;

    .cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .card {
      width: 50%;
      padding: 1rem;
      border-radius: 24px;
      background: #000;
    }
  }
}
</style>